import { createContext, useEffect, useState } from 'react';

import { Card, Col, Modal as ModalAnt, Row } from 'antd';
import { Formik } from 'formik';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  addOfferOrderIntent,
  createACHRelationship,
  getACHRelationships,
  getConfigs,
  getPlaidLinkToken,
} from 'src/actions';
import { DefinitionConstant, TransferConstant } from 'src/constants';
import { useAccountSelector, useFindMinimalDepositAmountForInvestment, useAccountBalance } from 'src/hooks';
import { MFormCurrencyField, MFormCurrencyInput, MFormSelect, MButton, MAlert, MModal } from 'src/lib';
import { OfferOrderIntent, OfferDetails } from 'src/models';
import { getCurrencyFormatter } from 'src/utils';

import CurrencyField from '../../../../../../lib/Miscellaneous/FormattedFields/CurrencyField/CurrencyField';
import Spinner from '../../../../../../lib/Miscellaneous/Spinner';
import { OpenPlaidLinkBankAccount } from '../../../../../../lib/OpenPlaidLinkBankAccount/OpenPlaidLinkBankAccount';
import { ConfigTypeEnum } from '../../../../../../models/configs.enum';
import { ConfigBankLink, Configs } from '../../../../../../models/configs.model';
import { ContextCardEnumTypes } from '../../../../../Cashiering/components/BankAccountsAndFunding/constants';
import BankLinkCreate from '../../../../../Cashiering/components/BankLinkCreate/BankLinkCreate';
import BankLinkSubDisclaimer from '../../../../../Cashiering/components/BankLinkSubDisclaimer/BankLinkSubDisclaimer';

import * as Styles from './AchPayment.styles';

export const ORDER_PATH = 'order';

const ModalContext = createContext<string | null>(null);

const config = {
  title: 'Bank Account Linking information',
  content: <ModalContext.Consumer>{name => `${name}`}</ModalContext.Consumer>,
};

export interface NewDepositFormValues {
  transferAmount?: string;
  bankAccount?: string;
}

export interface AchPaymentProps {
  isValid: (value: boolean) => void;
}

export const AchPayment = ({ isValid }: AchPaymentProps) => {
  const dispatch = useDispatch();

  const offerDetails: OfferDetails = useSelector((state: any) => state.offers.offerDetails?.data);
  const achRelationships = useSelector((state: any) => state.cashiering.achRelationships.data);
  const loadingAchRelationships = useSelector((state: any) => state.cashiering.achRelationships.__requested);
  const loadingCreateAchRelationships = useSelector((state: any) => state.cashiering.createACHRelationship.__requested);
  const plaidLinkToken = useSelector((state: any) => state.cashiering.plaidLinkToken.data?.linkToken);
  const isPlaidLinkBankAccountTokenLoading = useSelector((state: any) =>
    Boolean(state.cashiering.plaidLinkToken?.__requested),
  );
  const configsList: Configs[] = useSelector((state: any) => state.configs?.configsList?.data?.data || null);
  const offerOrderIntent: OfferOrderIntent = useSelector((state: any) => state.offerOrders.intent);
  const [newDepositFormErrors, setNewDepositFormErrors] = useState<NewDepositFormValues>();
  const [newDepositFormInitialValues, setNewDepositFormInitialValues] = useState<NewDepositFormValues>({});
  const [shouldOpenPlaid, setShouldOpenPlaid] = useState<boolean>(false);
  const [bankAccountList, setBankAccountList] = useState<{ label: string; value: string }[]>([]);
  const [hasBankLinkConfig, setHasBankLinkConfig] = useState<ConfigBankLink | null>();
  const [bankLinkPayload, setBankLinkPayload] = useState<any>({});

  const [hasBankAccount, setHasBankAccount] = useState(false);
  const [transferAmount, setTransferAmount] = useState<string>();
  const [contextCardVisible, setContextCardVisible] = useState<ContextCardEnumTypes | null>(null);
  const [modal, modalHolder] = ModalAnt.useModal();
  const { cashAvailable } = useAccountBalance();
  const { account } = useAccountSelector();
  const minimalDepositAmount = useFindMinimalDepositAmountForInvestment();

  const quantity = offerOrderIntent.quantity;

  const hasEnoughMoneyWithDepositAmountOnConditional = () => {
    const transferAmountNumber = Number(transferAmount) ?? 0;

    if (cashAvailable <= 0) {
      return offerOrderIntent.totalInvestment <= transferAmountNumber;
    } else {
      return offerOrderIntent.totalInvestment <= cashAvailable + transferAmountNumber;
    }
  };

  const hasEnoughMoneyWithDepositAmountOnStandard = () => {
    const transferAmountNumber = Number(transferAmount) ?? 0;

    if (cashAvailable <= 0) {
      return offerOrderIntent.price * quantity <= transferAmountNumber;
    } else {
      return offerOrderIntent.price * quantity <= cashAvailable + transferAmountNumber;
    }
  };

  const hasEnoughMoneyWithDepositAmount = () =>
    offerOrderIntent.isConditional
      ? hasEnoughMoneyWithDepositAmountOnConditional()
      : hasEnoughMoneyWithDepositAmountOnStandard();

  const findRemainingDepositAmountOnConditionalOffer = () => {
    const transferAmountNumber = Number(transferAmount) ?? 0;

    if (cashAvailable <= 0) {
      return offerOrderIntent.totalInvestment - transferAmountNumber;
    } else {
      return offerOrderIntent.totalInvestment - cashAvailable - transferAmountNumber;
    }
  };

  const findRemainingDepositAmountOnStandardOffer = () => {
    const transferAmountNumber = Number(transferAmount) ?? 0;

    if (cashAvailable <= 0) {
      return offerOrderIntent.price * quantity - transferAmountNumber;
    } else {
      return offerOrderIntent.price * quantity - cashAvailable - transferAmountNumber;
    }
  };

  const findRemainingDepositAmount = () =>
    offerOrderIntent.isConditional
      ? findRemainingDepositAmountOnConditionalOffer()
      : findRemainingDepositAmountOnStandardOffer();

  const onLinkBankAccount = () => {
    if (hasBankLinkConfig?.disableInFunnel) return modal.info(config);
    setContextCardVisible(ContextCardEnumTypes.BANK_LINK_ACTION);
  };

  const onPlaidSuccess = (plaidBankLinkSuccessPayload: any) => {
    let plaidAccounts = plaidBankLinkSuccessPayload.metadata.accounts.map((item: any) => {
      return {
        bankAccountType: `${item.subtype[0].toUpperCase()}${item.subtype.slice(1)}`,
        plaidAccountId: item.id,
        bankAccountMask: item.mask,
      };
    });

    setBankLinkPayload({
      plaidPublicToken: plaidBankLinkSuccessPayload.publicToken,
      institutionId: plaidBankLinkSuccessPayload.metadata.institution.institution_id,
      bankAccounts: plaidAccounts,
    });

    setContextCardVisible(ContextCardEnumTypes.BANK_LINK_CREATE);
  };

  const onPlaidExit = () => {
    setContextCardVisible(null);
    setShouldOpenPlaid(false);
  };

  const renderNewDepositForm = () => {
    return (
      <Formik<NewDepositFormValues>
        initialValues={newDepositFormInitialValues}
        enableReinitialize
        validateOnMount
        validate={value => {
          if (isNaN(Number(value.transferAmount)) || Number(value.transferAmount) < 0) {
            setNewDepositFormErrors({ transferAmount: 'Amount must be a positive number' });

            return;
          }

          if (TransferConstant.MAX_AMOUNT < Number(value.transferAmount)) {
            setNewDepositFormErrors({
              transferAmount: `The maximum transfer amount for ACH is ${getCurrencyFormatter().format(
                TransferConstant.MAX_AMOUNT,
              )} per Order. To invest over ${getCurrencyFormatter().format(
                TransferConstant.MAX_AMOUNT,
              )} on an Order, please select Wire or Check transfer.`,
            });

            return;
          }
          setNewDepositFormErrors({});
        }}
        onSubmit={() => {}}>
        {form => {
          return (
            <div className={Styles.container}>
              <Row gutter={[, 16]}>
                {!hasEnoughMoneyWithDepositAmount() && (
                  <MAlert
                    type='error'
                    description={
                      <span>
                        You do not have enough money to successfully complete the order. Please add a minimum of{' '}
                        <CurrencyField value={findRemainingDepositAmount()} /> {'.'}
                      </span>
                    }
                  />
                )}
                <span className={Styles.depositTitle} data-testid={'confirm-order-transfer-ach-info'}>
                  The requested order amount requires you to transfer funds into your account.
                </span>
                <Col span={24}>
                  <MFormCurrencyField
                    label='Cash Avaialbe'
                    value={cashAvailable}
                    tooltip={{ type: 'popover', content: DefinitionConstant.CASH_AVAILABLE, icon: 'question' }}
                  />
                </Col>
                <Col span={24}>
                  <MFormSelect
                    label='From Account'
                    defaultValue={bankAccountList?.[0].value}
                    value={form.values.bankAccount}
                    options={bankAccountList}
                    onChange={value => {
                      form.setFieldValue('bankAccount', value);
                      dispatch(
                        addOfferOrderIntent({
                          ...offerOrderIntent,
                          achDeposit: { ...offerOrderIntent.achDeposit, relationshipId: value },
                        }),
                      );
                    }}
                  />
                </Col>
                <Col span={24}>
                  <MFormCurrencyInput
                    label='Transfer Amount'
                    value={form.values.transferAmount}
                    onChange={value => {
                      form.setFieldValue('transferAmount', value);
                      setTransferAmount(value);
                      dispatch(
                        addOfferOrderIntent({
                          ...offerOrderIntent,
                          achDeposit: { ...offerOrderIntent.achDeposit, amount: value },
                        }),
                      );
                    }}
                    error={form.errors.transferAmount || newDepositFormErrors?.transferAmount}
                  />
                </Col>
              </Row>
            </div>
          );
        }}
      </Formik>
    );
  };

  const renderLinkBankAccountForm = () => {
    const text = (
      <span>
        You do not have any linked bank accounts to successfully complete the order.
        <br />
        To continue, please link a bank account.
      </span>
    );

    return (
      <Card className={Styles.cardContainer}>
        <div className={Styles.linkBankContainer}>
          <div className={Styles.linkBankText}>
            <p data-testid={'confirm-order-ach-payment-text'}>{text}</p>
          </div>
          <div className={Styles.linkBankButton}>
            <MButton type='secondary' onClick={onLinkBankAccount} size='small'>
              Link Bank Account
            </MButton>
          </div>
        </div>
      </Card>
    );
  };

  const renderLinkBankModal = () => {
    return (
      <>
        <Formik
          enableReinitialize
          initialValues={{
            nickname: '',
          }}
          validateOnMount
          validateOnBlur
          validateOnChange
          onSubmit={values => {
            dispatch(
              createACHRelationship(
                {
                  ...bankLinkPayload,
                  nickName: values.nickname,
                },
                account?.accountId,
              ),
            );
            setContextCardVisible(null);
          }}>
          {form => {
            return (
              <MModal
                title='Disclaimer'
                customWidth={350}
                customHeight={450}
                verticalBottoms={
                  contextCardVisible === ContextCardEnumTypes.BANK_LINK_CREATE ||
                  contextCardVisible === ContextCardEnumTypes.BANK_LINK_ACTION
                }
                form={contextCardVisible === ContextCardEnumTypes.BANK_LINK_ACTION ? undefined : form}
                visible={Boolean(contextCardVisible)}
                primaryButtonText={
                  contextCardVisible === ContextCardEnumTypes.BANK_LINK_ACTION ? 'Continue' : 'Finish Linking'
                }
                tertiaryButtonText={contextCardVisible === ContextCardEnumTypes.BANK_LINK_ACTION ? 'Close' : undefined}
                onPrimaryButtonClick={() => {
                  if (contextCardVisible === ContextCardEnumTypes.BANK_LINK_ACTION) {
                    setShouldOpenPlaid(true);
                    setContextCardVisible(null);

                    return;
                  }

                  if (contextCardVisible === ContextCardEnumTypes.BANK_LINK_CREATE) {
                    setShouldOpenPlaid(false);
                    form.submitForm();

                    return;
                  }
                }}
                onTertiaryButtonClick={() => {
                  setContextCardVisible(null);
                }}
                onClose={() => {
                  setContextCardVisible(null);
                }}>
                <div>
                  {contextCardVisible === ContextCardEnumTypes.BANK_LINK_CREATE && <BankLinkCreate form={form} />}
                  {contextCardVisible !== ContextCardEnumTypes.BANK_LINK_CREATE && <BankLinkSubDisclaimer />}
                </div>
              </MModal>
            );
          }}
        </Formik>
      </>
    );
  };

  useEffect(() => {
    if (!achRelationships && !loadingAchRelationships) dispatch(getACHRelationships(account?.accountId));
    dispatch(getConfigs());
  }, [dispatch]);

  useEffect(() => {
    const bankLinkConfig: Configs | null =
      configsList?.find((config: Configs) => config.configType === ConfigTypeEnum.BANK_LINK) || null;
    const bankLinkData: ConfigBankLink | null = bankLinkConfig?.appConfig?.bankLink || null;
    setHasBankLinkConfig(bankLinkData);
  }, [configsList]);

  useEffect(() => {
    setHasBankAccount(!_.isEmpty(achRelationships));
    const bankAccountOptions =
      (!loadingAchRelationships &&
        Array.isArray(achRelationships) &&
        achRelationships.map((ach: any) => {
          const { bankAccountType, nickName, bankAccount, id } = ach.tradingBlockACHRelationship;

          return { label: `${bankAccountType} (${nickName}) ${bankAccount}`, value: id };
        })) ||
      [];
    setBankAccountList(bankAccountOptions);
  }, [achRelationships, loadingAchRelationships]);

  useEffect(() => {
    if (!_.isEmpty(bankAccountList) && offerDetails) {
      const minValue = minimalDepositAmount.toString();

      setTransferAmount(minValue);
      setNewDepositFormInitialValues({
        bankAccount: bankAccountList[0]['value'],
        transferAmount: minValue,
      });

      dispatch(
        addOfferOrderIntent({
          ...offerOrderIntent,
          achDeposit: { amount: minValue, relationshipId: bankAccountList[0]['value'] },
        }),
      );
    }
  }, [bankAccountList, offerDetails]);

  useEffect(() => {
    isValid(
      hasBankAccount && hasEnoughMoneyWithDepositAmount() && Number(transferAmount) <= TransferConstant.MAX_AMOUNT,
    );
  }, [hasBankAccount, transferAmount, cashAvailable]);

  useEffect(() => {
    if (!plaidLinkToken) {
      dispatch(getPlaidLinkToken(account?.accountId));
    }
  }, []);

  if (loadingAchRelationships || loadingCreateAchRelationships || isPlaidLinkBankAccountTokenLoading) {
    return <Spinner />;
  }

  if (hasBankAccount) {
    return renderNewDepositForm();
  }

  return (
    <>
      {renderLinkBankAccountForm()}
      <ModalContext.Provider value={hasBankLinkConfig?.funnelDisclaimer || ''}>{modalHolder}</ModalContext.Provider>
      {contextCardVisible && renderLinkBankModal()}
      {plaidLinkToken && shouldOpenPlaid && (
        <OpenPlaidLinkBankAccount token={plaidLinkToken} onExit={onPlaidExit} onSuccess={onPlaidSuccess} />
      )}
    </>
  );
};
