import { css } from '@emotion/css';

import { ColorConstant, Display, Font, Spacing } from '../../styles';

export const container = css`
  ${Font.md}
  color: ${ColorConstant.GRAY7};
  ${Display.flex}
  ${Spacing.mt12}
`;
