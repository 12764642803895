import { Col, Row } from 'antd';
import CurrencyInput from 'react-currency-input-field';

import { MFormItemError } from '../MFormItemError/MFormItemError';

import * as Styles from './MCurrencyInput.styles';

export interface MCurrencyInputProps {
  name?: string;
  placeholder?: string;
  defaultValue?: string;
  value?: string;
  error?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  className?: string;
  weight?: 'light' | 'medium';
  testId?: string;
}

export const MCurrencyInput = ({
  name,
  placeholder,
  defaultValue,
  value,
  disabled,
  error,
  onChange,
  testId,
}: MCurrencyInputProps) => {
  const _onChange = (value: string | undefined) => {
    if (onChange && value) {
      onChange(value);
    }
  };

  return (
    <Row className={Styles.inputContainer}>
      <Col span={24}>
        <CurrencyInput
          name={name}
          placeholder={placeholder}
          value={value}
          defaultValue={defaultValue}
          disabled={disabled}
          intlConfig={{ locale: 'en-US', currency: 'USD' }}
          allowNegativeValue={false}
          decimalScale={2}
          onValueChange={_onChange}
          className={Styles.input}
        />
      </Col>
      <Col span={24} data-testid={testId + '-' + 'error'}>
        <MFormItemError value={error} />
      </Col>
    </Row>
  );
};
