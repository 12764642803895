import { PropsWithChildren } from 'react';

import { Col, Radio, Row } from 'antd';
import { isString } from 'lodash';

import { MFormItemError } from '../MFormItemError/MFormItemError';
import { MRadio } from '../MRadio/MRadio';
import { MTooltip } from '../MTooltip/MTooltip';

import * as Styles from './MRadioGroup.styles';

const { Group } = Radio;

export interface MRadioGroupOption {
  label: string | JSX.Element;
  value: string;
  description?: string | JSX.Element;
  alert?: string | JSX.Element;
  tooltip?: {
    type: 'modal' | 'popover';
    title?: string;
    content?: string;
    icon?: 'info' | 'question';
  };
}

export interface MRadioGroupProps {
  value?: string;
  defaultValue?: string;
  onChange: (value: string) => void;
  options: MRadioGroupOption[];
  disabled?: boolean;
  error?: string;
  align?: 'horizontal' | 'vertical';
  testId?: string;
}

export const MRadioGroup = ({
  options,
  value,
  defaultValue,
  onChange,
  error,
  align = 'horizontal',
  testId,
}: PropsWithChildren<MRadioGroupProps>) => {
  const getTestId = (label: string | JSX.Element) => {
    if (testId && isString(label)) {
      return testId + '-' + label.replace(/\s+/g, '-').toLowerCase();
    }
  };

  return (
    <Row>
      <Col span={24}>
        <Group className={Styles.group(align)} defaultValue={defaultValue}>
          {options.map(anOption => (
            <MRadio
              testId={getTestId(anOption.label)}
              key={anOption.value}
              value={anOption.value}
              onChange={onChange}
              alert={anOption.value === value && anOption.alert ? anOption.alert : undefined}
              description={anOption.description}
              className={Styles.button}>
              <span>{anOption.label}</span>
              {anOption.tooltip && (
                <MTooltip type={anOption.tooltip.type} title={anOption.tooltip.title} icon={anOption.tooltip.icon}>
                  {anOption.tooltip.content}
                </MTooltip>
              )}
            </MRadio>
          ))}
        </Group>
      </Col>
      <Col span={24}>
        <MFormItemError value={error} />
      </Col>
    </Row>
  );
};
